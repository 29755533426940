


















import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppExportTreeNodeSpotsViewQuery,
  AppExportTreeNodeSpotsViewQueryVariables,
} from './spots/__generated__/AppExportTreeNodeSpotsViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    treeNodes: {
      query,
      variables(this: TreeNodeView): AppExportTreeNodeSpotsViewQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class TreeNodeView extends Mixins(AppRedirectionMixin, GroupsMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppExportTreeNodeSpotsViewQuery;
}
