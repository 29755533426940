











import { Component, Vue } from 'vue-property-decorator';
import { AppExportAppViewEntryQuery } from './__generated__/AppExportAppViewEntryQuery';
import entryQuery from './entry.gql';

@Component
export default class AppView extends Vue {
  private async created(): Promise<void> {
    const { data } = await this.$apollo.query<AppExportAppViewEntryQuery>({ query: entryQuery });
    const treeNodeId = data.treeNodes.first.id;

    this.$router.replace({ name: 'AppExport/TreeNode/CreateExport', params: { treeNodeId } });
  }
}
