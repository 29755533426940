import { NumberProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class PaginationQueryStringMixin extends Vue {
  @NumberProp(30, 1)
  protected readonly pageSize!: number;

  protected get count(): number {
    return 0;
  }

  protected get more(): boolean | undefined {
    return undefined;
  }

  protected get totalPages(): number {
    return this.more === undefined ? Math.ceil(this.count / this.take) : this.currentPage + Number(this.more);
  }

  protected get currentPage(): number {
    const page = Number(this.$route.query.page);

    return isNaN(page) ? 1 : Math.max(1, page);
  }

  protected set currentPage(value: number) {
    if (value === this.currentPage) {
      return;
    }

    void this.$router.replace({ query: { ...this.$route.query, page: value < 2 ? undefined : String(value) } });
  }

  protected get skip(): number {
    return this.take * (this.currentPage - 1);
  }

  protected get take(): number {
    return this.pageSize;
  }
}
