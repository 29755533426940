





























































































































































































































































import { StringProp } from '@/util/prop-decorators';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppExportTreeNodeCreateExportViewQuery,
  AppExportTreeNodeCreateExportViewQuery_treeNodes_first_Directory_customer_exportConfigurations_items,
  AppExportTreeNodeCreateExportViewQuery_treeNodes_first_Directory_customer_externalSftpConfigurations_items,
  AppExportTreeNodeCreateExportViewQueryVariables,
} from './__generated__/AppExportTreeNodeCreateExportViewQuery';
import {
  AppExportTreeNodeCreateExportViewExportPresetsQuery,
  AppExportTreeNodeCreateExportViewExportPresetsQueryVariables,
} from './__generated__/AppExportTreeNodeCreateExportViewExportPresetsQuery';
import addExportConfiguration from './add.gql';
import {
  AppExportTreeNodeAddExportConfigurationMutation,
  AppExportTreeNodeAddExportConfigurationMutationVariables,
} from './__generated__/AppExportTreeNodeAddExportConfigurationMutation';
import exportPresetsQuery from './export-presets.gql';
import query from './view.gql';
import { ORIGIN_ENDPOINT } from '@/env';
import { keycloak } from '@/keycloak';
import { Option } from '@/features/ui/inputs/model';
import { Column } from '@/features/ui/table/model';
import { IntervalUnit, TreeNodeType } from '@/types/iot-portal';
import {
  AppExportTreeNodeRemoveExportConfigurationMutation,
  AppExportTreeNodeRemoveExportConfigurationMutationVariables,
} from './__generated__/AppExportTreeNodeRemoveExportConfigurationMutation';
import removeExportConfiguration from './remove.gql';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import slash from 'slash';
import Busyable, { Busy } from '@/features/ui/mixins/busyable';

const TIMEFRAME_CONFIG = {
  YEARLY: {
    label: 'Jährlich',
    datepickerMinView: 'year',
  },
  MONTHLY: {
    label: 'Monatlich',
    datepickerMinView: 'month',
  },
  WEEKLY: {
    label: 'Wöchentlich',
    datepickerMinView: 'day',
  },
  CUSTOM: {
    label: 'Angepasst',
    datepickerMinView: 'day',
  },
  QUARTER_HOURLY: {
    label: 'Viertelstündlich',
    datepickerMinView: 'day',
  },
};

const INTERVAL_CONFIG = {
  YEARLY: {
    label: 'Jährlich',
    intervalUnit: 'Year',
  },
  MONTHLY: {
    label: 'Monatlich',
    intervalUnit: 'Month',
  },
  WEEKLY: {
    label: 'Wöchentlich',
    intervalUnit: 'Week',
  },
  CUSTOM: {
    label: 'Täglich',
    intervalUnit: 'Day',
  },
  QUARTER_HOURLY: {
    label: 'Viertelstündlich',
    intervalUnit: 'QUARTER_HOURLY',
  },
};

const autoExportOptions: Option[] = [
  {
    label: 'per E-Mail',
    value: 'mail',
  },
  {
    label: 'per SFTP-Server',
    value: 'sftp',
  },
];

const autoExportColumns: Column[] = [
  { name: 'format', field: 'exportPreset.name', label: 'Export Format' },
  { name: 'interval', field: 'exportInterval', label: 'Zeitraum' },
  { name: 'sender', label: 'Versandart' },
  { name: 'created', field: 'creationDate', label: 'angelegt am...' },
  { name: 'executed', label: 'zuletzt ausgeführt am...' },
  { name: 'action', label: 'Aktionen' },
];

const EXPORT_TYPE_CONFIG = {
  DEFAULT: 'DEFAULT',
  STEFFENS: 'STEFFENS',
};

interface ExportOption {
  id: string;
  name: string;
  timeframes: (keyof typeof TIMEFRAME_CONFIG)[];
  exportPresetType: keyof typeof EXPORT_TYPE_CONFIG;
}

interface ExportTimeframeOption {
  timeframe: keyof typeof TIMEFRAME_CONFIG;
  label: string;
  intervalUnit?: IntervalUnit;
}

interface FormData {
  treeNodeIds: string[];
  selectedCustomer: string;
  selectedExportType: ExportOption;
  selectedExportTimeframe: ExportTimeframeOption;
  startDate: Date;
  stopDate?: Date;
}

@Component({
  apollo: {
    treeNodes: {
      query,
      variables(this: CreateExportView): AppExportTreeNodeCreateExportViewQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
    exportPresets: {
      query: exportPresetsQuery,
      skip(this: CreateExportView): boolean {
        return this.customerId === undefined;
      },
      variables(this: CreateExportView): AppExportTreeNodeCreateExportViewExportPresetsQueryVariables {
        return { customerId: this.customerId as string };
      },
    },
  },

  data() {
    return {
      formatDescriptionTab: undefined,
      treeNodes: undefined,
      exportPresets: undefined,
      exportOptions: [],
    };
  },
})
export default class CreateExportView extends Mixins(Busyable) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly treeNodes?: AppExportTreeNodeCreateExportViewQuery['treeNodes'];
  private readonly exportPresets?: AppExportTreeNodeCreateExportViewExportPresetsQuery['exportPresets'];

  private loading = false;

  private selectedHour: Option = { value: 0, label: '00' };

  private selectedMinutes: Option = { value: 0, label: '00' };

  private selectHourOptions = [...Array(24).keys()].map((hour) => ({
    value: hour,
    label: hour < 10 ? '0' + hour : hour,
  }));

  private selectMinutesOptions = [...Array(46).keys()]
    .filter((value) => value % 5 === 0)
    .map((minute) => ({
      value: minute,
      label: minute < 10 ? '0' + minute : minute,
    }));

  private set toggleLoader(value: boolean) {
    this.loading = value;
  }

  private get toggleLoader(): boolean {
    return this.loading;
  }

  private get rootIds(): string[] {
    return this.treeNodes === undefined ? [] : [this.treeNodes.first.path.first.id];
  }

  private get exportPresetOptions(): ExportOption[] {
    return this.exportPresets?.items ?? [];
  }

  private get customerId(): string | undefined {
    return this.treeNodes?.first.customer.id;
  }

  private get externalSftpConfigurations():
    | AppExportTreeNodeCreateExportViewQuery_treeNodes_first_Directory_customer_externalSftpConfigurations_items[]
    | undefined {
    return this.treeNodes?.first.customer.externalSftpConfigurations.items;
  }

  private get externalSftpConfigurationExists(): boolean {
    return (this.treeNodes?.first.customer.externalSftpConfigurations.count ?? 0) > 0;
  }

  private get autoExports():
    | AppExportTreeNodeCreateExportViewQuery_treeNodes_first_Directory_customer_exportConfigurations_items[]
    | undefined {
    return this.treeNodes?.first.customer.exportConfigurations.items;
  }

  private get intervalUnitLabels(): { [key: string]: string } {
    const mapping: { [key: string]: string } = {};
    for (const value of Object.values(INTERVAL_CONFIG)) {
      mapping[value.intervalUnit] = value.label;
    }
    return mapping;
  }

  private get autoExportTreeNodesTypes(): TreeNodeType[] {
    return [TreeNodeType.RootDirectory, TreeNodeType.Directory, TreeNodeType.Property, TreeNodeType.PropertyGroup];
  }

  private readonly TIMEFRAME_CONFIG = TIMEFRAME_CONFIG;

  private readonly autoExportOptions = autoExportOptions;

  private readonly autoExportColumns = autoExportColumns;

  private submitSuccessful = false;
  private submitWithErrors = false;
  private formatDescriptionTab?: ExportOption;

  private getTimeframeOptions(type?: ExportOption): ExportTimeframeOption[] {
    return type?.timeframes.map((timeframe) => ({ timeframe, label: TIMEFRAME_CONFIG[timeframe].label })) ?? [];
  }

  private getAutoExportIntervalOptions(type?: ExportOption): ExportTimeframeOption[] {
    return (
      type?.timeframes.map((timeframe) => ({
        timeframe,
        label: INTERVAL_CONFIG[timeframe].label,
        value: INTERVAL_CONFIG[timeframe].intervalUnit,
      })) ?? []
    );
  }

  private formatDate(date: Date): string {
    return DateTime.fromJSDate(date).toUTC().toFormat('dd.LL.yyyy');
  }

  private formatDateTime(date: Date): string {
    return `${DateTime.fromJSDate(date).toUTC().toFormat('dd.LL.yyyy HH:mm')} (UTC)`;
  }

  private formatLastExecution(exportInterval: string, lastExecution: Date): string {
    if (exportInterval === 'QUARTER_HOURLY') {
      return this.formatDateTime(lastExecution);
    } else {
      return this.formatDate(lastExecution);
    }
  }

  private getDateFormat(type?: ExportTimeframeOption): (date: Moment) => string {
    return (date: Moment): string => {
      // for visual purposes, the non exclusive dates are more intuitive

      if (!type || type?.timeframe === 'CUSTOM') {
        return date.format('L');
      }

      if (type?.timeframe === 'QUARTER_HOURLY') {
        const dateTimeStart = moment(date).set({
          hour: Number(this.selectedHour.value),
          minute: Number(this.selectedMinutes.value),
        });
        const dateTimeEnd = moment(dateTimeStart).add(15, 'minutes');
        return `${dateTimeStart.format('L HH:mm')}–${dateTimeEnd.format('L HH:mm')}`;
      }

      // Exclusive does makes sense for API, but inclusive dates are more natural to a user
      const inclusiveEnd = (date: Moment, period: 'year' | 'month' | 'week' | 'day'): Moment =>
        // Usage of isoWeek to set monday as week's begin
        date.subtract(1, period).endOf(period === 'week' ? 'isoWeek' : period);

      const dateRange = this.getDateRange(date, date, type);

      return `${dateRange.start.format('L')}–${inclusiveEnd(dateRange.stop, dateRange.period).format('L')}`;
    };
  }

  private getDateRange(
    startDate: Moment,
    stopDate?: Moment,
    type?: ExportTimeframeOption,
  ): { start: Moment; stop: Moment; period: 'year' | 'month' | 'week' | 'day' } {
    startDate = startDate.clone();
    stopDate = stopDate?.clone() ?? startDate.clone();
    if (type?.timeframe === 'WEEKLY') {
      return { start: startDate.startOf('isoWeek'), stop: stopDate.add(1, 'week').startOf('isoWeek'), period: 'week' };
    }

    if (type?.timeframe === 'MONTHLY') {
      return { start: startDate.startOf('month'), stop: stopDate.add(1, 'month').startOf('month'), period: 'month' };
    }

    if (type?.timeframe === 'YEARLY') {
      return { start: startDate.startOf('year'), stop: stopDate.add(1, 'year').startOf('year'), period: 'year' };
    }

    if (type?.timeframe === 'QUARTER_HOURLY') {
      const dateTimeStart = moment
        .utc(startDate)
        .set({
          hour: Number(this.selectedHour.value),
          minute: Number(this.selectedMinutes.value),
        })
        .add(1, 'day');
      const dateTimeEnd = moment(dateTimeStart).add(15, 'minutes');
      return { start: dateTimeStart, stop: dateTimeEnd, period: 'day' };
    }

    return { start: startDate.startOf('day'), stop: stopDate.add(1, 'day').startOf('day'), period: 'day' };
  }

  private getTimeframe(timeframe?: ExportTimeframeOption): keyof typeof TIMEFRAME_CONFIG {
    return timeframe?.timeframe ?? 'CUSTOM';
  }

  private getOpenDate(type?: ExportTimeframeOption): Date {
    return this.getDisabledDates(type).from;
  }

  private getDisabledDates(type?: ExportTimeframeOption): { from: Date } {
    const disableFrom: Date = moment().subtract(1, 'day').toDate();

    if (!type) {
      return { from: disableFrom };
    }

    if (type.timeframe === 'WEEKLY') {
      return { from: moment().subtract(1, 'week').endOf('isoWeek').toDate() };
    }

    if (type.timeframe === 'MONTHLY') {
      return { from: moment().subtract(1, 'month').endOf('month').toDate() };
    }

    if (type.timeframe === 'YEARLY') {
      return { from: moment().subtract(1, 'year').endOf('year').toDate() };
    }

    return {
      from: disableFrom,
    };
  }
  private pluckId<T>(object: { id: T } | null): T | null {
    return object?.id ?? null;
  }

  private pluckIds<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }

  private get initialData(): Record<string, unknown> {
    return { treeNodes: this.treeNodes === undefined ? [] : [this.treeNodes.first] };
  }

  private async submit(formData: FormData): Promise<void> {
    const dateRange = this.getDateRange(
      moment(formData.startDate),
      formData.stopDate ? moment(formData.stopDate) : formData.stopDate,
      formData.selectedExportTimeframe,
    );

    this.submitSuccessful = false;
    this.submitWithErrors = false;

    const urlConstruct = new URL(`${ORIGIN_ENDPOINT}/api/export`);

    for (const id of formData.treeNodeIds) {
      urlConstruct.searchParams.append('treeNodeIds', id);
    }

    urlConstruct.searchParams.append('exportPresetId', formData.selectedExportType.id);
    urlConstruct.searchParams.append('exportPresetType', formData.selectedExportType.exportPresetType);
    urlConstruct.searchParams.append('start', dateRange.start.toISOString());
    urlConstruct.searchParams.append('stop', dateRange.stop.toISOString());

    this.toggleLoader = true;
    fetch(urlConstruct.toString(), {
      method: 'GET',
      headers: { authorization: `Bearer ${keycloak?.token ?? ''}` },
    }).then(async (res) => {
      if (!res.ok) {
        this.submitWithErrors = true;
      } else {
        const fileName = res.headers
          .get('content-disposition')
          ?.split(';')
          ?.find((s) => s.trim().toLowerCase().startsWith('filename='))
          ?.replace(/["']/g, '')
          ?.trim()
          ?.split('filename=')?.[1];
        const blob = await res.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName ?? 'unnamed'); //or any other extension document.body.appendChild(link);
        link.click();
        this.submitSuccessful = true;
      }
      this.toggleLoader = false;
    });
  }

  @Busy()
  private async submitNewAutoExport(formData: {
    treeNodeIds: string[];
    selectedExportType: ExportOption;
    selectedExportTimeframe: ExportTimeframeOption & { value: IntervalUnit };
    exportRoute: 'sftp' | 'mail';
    externalSftpConfigurationId?: VendorApiId;
    sftpPath?: string;
    mailAddress?: string;
  }): Promise<string | undefined> {
    try {
      if (!this.customerId || !formData.selectedExportTimeframe) {
        throw new Error('Die Exportkonfiguration konnte nicht hinzugefügt werden');
      }

      const { data } = await this.$apollo.mutate<
        AppExportTreeNodeAddExportConfigurationMutation,
        AppExportTreeNodeAddExportConfigurationMutationVariables
      >({
        mutation: addExportConfiguration,
        variables: {
          input: {
            exportInterval: IntervalUnit[formData.selectedExportTimeframe.value],
            customerId: this.customerId,
            exportPresetId: formData.selectedExportType.id,
            externalSftpConfigurationId: formData.externalSftpConfigurationId,
            sftpPath: formData.sftpPath ? slash(formData.sftpPath) : null,
            treeNodeIds: formData.treeNodeIds,
            mailAddress: formData.mailAddress,
          },
        },
      });

      if (!data) {
        throw new Error('Die Exportkonfiguration konnte nicht hinzugefügt werden');
      }

      this.$apollo.queries.treeNodes.refetch();

      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Die Exportkonfiguration wurde angelegt.', class: 'success' }],
      });

      return data.addExportConfiguration.exportConfiguration.id;
    } catch (e) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Die Exportkonfiguration konnte nicht hinzugefügt werden!', class: 'error' }],
      });
      throw e;
    }
  }

  private async removeAutoExport(id: string): Promise<string | undefined> {
    try {
      if (!window.confirm(`Sind Sie sich sicher, dass Sie den automatischen Export entfernen möchten?`)) {
        return;
      }

      const { data } = await this.$apollo.mutate<
        AppExportTreeNodeRemoveExportConfigurationMutation,
        AppExportTreeNodeRemoveExportConfigurationMutationVariables
      >({
        mutation: removeExportConfiguration,
        variables: { input: { exportConfigurationId: id } },
      });

      if (!data) {
        throw new Error('Die Exportkonfiguration konnte nicht entfernt werden');
      }

      this.$apollo.queries.treeNodes.refetch();

      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Die Exportkonfiguration wurde entfernt.', class: 'success' }],
      });

      return data.removeExportConfiguration.exportConfigurationId;
    } catch (e) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Die Exportkonfiguration konnte nicht hinzugefügt werden!', class: 'error' }],
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private isDisabled({ stopDate, ...formData }: Record<string, unknown>): boolean {
    return Object.values(formData).some((value) => value === null);
  }
}
