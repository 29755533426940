

























import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { SpotTableFilterMixin } from '@/features/core/components/mixins/spot-table-filter';
import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { RootGetter } from '@/features/core/store';
import { SpotTableSpot } from '@/features/domain-ui/spot-table/model';
import { SpotsExportSpot } from '@/features/domain-ui/spots-export/model';
import { isDef } from '@/util/lang';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import exportSubscription from './export.gql';
import spotsQuery from './spots.gql';
import query from './view.gql';
import {
  AppExportTreeNodeSpotsViewExportSubscription,
  AppExportTreeNodeSpotsViewExportSubscriptionVariables,
} from './__generated__/AppExportTreeNodeSpotsViewExportSubscription';
import {
  AppExportTreeNodeSpotsViewQuery,
  AppExportTreeNodeSpotsViewQueryVariables,
} from './__generated__/AppExportTreeNodeSpotsViewQuery';
import {
  AppExportTreeNodeSpotsViewSpotsQuery,
  AppExportTreeNodeSpotsViewSpotsQueryVariables,
} from './__generated__/AppExportTreeNodeSpotsViewSpotsQuery';

@Component({
  apollo: {
    treeNodes: {
      query,
      pollInterval: 60000,
      variables(this: SpotsView): AppExportTreeNodeSpotsViewQueryVariables {
        return {
          treeNodeId: this.treeNodeId,
          roles: this.roles,
          healths: this.healths,
          manufacturers: this.manufacturers,
        };
      },
    },
    spots: {
      query: spotsQuery,
      pollInterval: 60000,
      variables(this: SpotsView): AppExportTreeNodeSpotsViewSpotsQueryVariables {
        return { ...this.variables, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { treeNodes: undefined, spots: undefined };
  },
})
export default class SpotsView extends Mixins(
  TreeNodeLocationGeneratorMixin,
  PaginationQueryStringMixin,
  SpotTableFilterMixin,
) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppExportTreeNodeSpotsViewQuery['treeNodes'];
  private readonly spots?: AppExportTreeNodeSpotsViewSpotsQuery['spots'];

  @RootGetter
  private readonly userId?: string;

  protected get count(): number {
    return this.spots !== undefined && 'spots' in this.spots.first ? this.spots.first.spots.count : 0;
  }

  private get variables(): AppExportTreeNodeSpotsViewExportSubscriptionVariables {
    return {
      treeNodeId: this.treeNodeId,
      roles: this.roles,
      healths: this.healths,
      manufacturers: this.manufacturers,
      descriptors: [{ start: '-P30D', stop: this.normalizedMetricsStopDate }],
      userId: this.userId,
    };
  }

  private onSpotClick(spot: SpotTableSpot): void {
    this.$router.push({
      name: 'AppManager/TreeNode/Spots/Spot',
      params: { treeNodeId: spot.path.items.slice(-1)[0].id, spotId: spot.id },
    });
  }

  private exportSpots(): Promise<SpotsExportSpot[]> {
    return new Promise((resolve) => {
      this.$apollo
        .subscribe<AppExportTreeNodeSpotsViewExportSubscription, AppExportTreeNodeSpotsViewExportSubscriptionVariables>(
          {
            query: exportSubscription,
            variables: this.variables,
          },
        )
        .map(({ data }) => (data?.spot ?? undefined) as SpotsExportSpot)
        .filter(isDef)
        .reduce((spots, spot) => (spots.push(spot), spots), new Array<SpotsExportSpot>())
        .subscribe(resolve);
    });
  }
}
